import React from 'react';
import { Modal, Box, TextField, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PartnerModal = ({ open, handleClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://berc.pk/submitpartner.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data).toString(),
      });

      if (response.ok) {
        toast.success('Email sent successfully');
      } else {
        toast.error('Email not sent, Please try again');
      }
    } catch (error) {
      toast.error('Error submitting form');
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto', // Add vertical scrollbar

        }}
      >
      

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                {...register('name', { required: 'Name is required' })}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Name"
                variant="outlined"
                fullWidth
                {...register('companyName', { required: 'Company name is required' })}
                error={!!errors.companyName}
                helperText={errors.companyName ? errors.companyName.message : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Job Title"
                variant="outlined"
                fullWidth
                {...register('jobTitle', { required: 'Job title is required' })}
                error={!!errors.jobTitle}
                helperText={errors.jobTitle ? errors.jobTitle.message : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: 'Invalid email address'
                  }
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Website"
                variant="outlined"
                fullWidth
                {...register('website')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                variant="outlined"
                fullWidth
                {...register('country', { required: 'Country is required' })}
                error={!!errors.country}
                helperText={errors.country ? errors.country.message : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                {...register('state', { required: 'State is required' })}
                error={!!errors.state}
                helperText={errors.state ? errors.state.message : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                {...register('city', { required: 'City is required' })}
                error={!!errors.city}
                helperText={errors.city ? errors.city.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                {...register('address', { required: 'Address is required' })}
                error={!!errors.address}
                helperText={errors.address ? errors.address.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                {...register('phone', { required: 'Phone is required' })}
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                {...register('message', { required: 'Message is required' })}
                error={!!errors.message}
                helperText={errors.message ? errors.message.message : ''}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default PartnerModal;
