import React, { useState, useEffect } from 'react';
import Loader from './components/Loader';
// import LetsTalk from './pages/LetsTalk';
import Home from './pages/Home';
import Team from './pages/Team';
import Navbar from './pages/Navbar';
import WhyUs from './pages/WhyUs';
import Services from './pages/Services';
import Gallery from './pages/Gallery';
import FooterText from './components/FooterText';
import NewsEvents from './pages/NewsEvents';
import WebinarModal from './components/modal/WebinarModal'; 

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Navbar />
          <Home />
          <WhyUs />
          <Services />
          <NewsEvents />
          <Team />
          <Gallery />
          <FooterText />
          {/* Display the modal */}
          <WebinarModal isOpen={isModalOpen} onClose={handleCloseModal} /> 
        </div>
      )}
    </div>
  );
}

export default App;
