import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const WhyUs = () => {
  return (
    <section id="why-us" className="min-h-screen flex flex-col items-center justify-center p-8" style={{ backgroundColor: "#fff" }}>
      <hr className="w-full border-gray-300 mb-10" />
      <div className="flex flex-col lg:flex-row max-w-6xl w-full">
        <div className="flex flex-col items-center justify-center w-full lg:w-1/3 mb-8 lg:mb-0">
          <h1 className="font-thin text-6xl md:text-7xl lg:text-8xl" style={{ color: "#32CD32" }}>Why Choose</h1>
          <h1 className="font-thin text-6xl md:text-7xl lg:text-8xl" style={{ color: "#32CD32" }}>BERC</h1>
          <FontAwesomeIcon icon={faQuestion} className="mt-4" style={{ fontSize: '100px', color: "#32CD32" }} />
        </div>
        <div className="w-full lg:w-2/3 text-left lg:pl-8">
          <p className="text-2xl text-gray-800 mb-8 text-justify">
            The establishment of the BERC marks a significant milestone in our commitment to advancing research and development in the field of energy, particularly in the area of sustainable building energy solutions. The Centre is Pakistan's first ever state-of-the-art centre working in the field of sustainable buildings design and construction. The centre serves as a hub for interdisciplinary collaboration, bringing together researchers, industry experts, and policymakers to address the energy challenges facing our society.
          </p>
          <p className="text-2xl text-gray-800 text-justify">
            BERC has pioneered in promising to provide solutions for Energy Efficiency in Buildings while focusing on four major SDGs including SDG 7: Affordable and Clean Energy, SDG 11: Sustainable Cities and Communities, SDG 12: Responsible Consumption and Production, and SDG 13: Climate Action. This center has a 28-member team comprising of Mechanical, Electrical, Civil, Architectural and Software Engineers.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
