// Import statements
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import logo from '../assets/berc-logo.jpeg';
import Partner from './Partner';
import ScrollToTopButton from '../components/ScrollToTopButton';

// Tools Images
import bpdImg1 from '../assets/toolsImages/bpd-img1.jpg';
import bpdImg2 from '../assets/toolsImages/bpd-img2.jpg';
import bpdImg3 from '../assets/toolsImages/bpd-img3.jpg';
import bpdImg4 from '../assets/toolsImages/bpd-img4.jpg';
import lcaImg1 from '../assets/toolsImages/lca-img1.jpg';
import lcaImg2 from '../assets/toolsImages/lca-img2.jpg';
import lcaImg3 from '../assets/toolsImages/lca-img3.jpg';
import lcaImg4 from '../assets/toolsImages/lca-img4.jpg';
import lcaImg5 from '../assets/toolsImages/lca-img5.jpg';
import lcaImg6 from '../assets/toolsImages/lca-img6.jpg';
import lcaImg7 from '../assets/toolsImages/lca-img7.jpg';
import rlfImg1 from '../assets/toolsImages/rlf1.jpeg';
import rlfImg2 from '../assets/toolsImages/rlf2.jpeg';
import rlfImg3 from '../assets/toolsImages/rlf3.jpeg';
import comImg3 from '../assets/toolsImages/com-img3.png';
import comImg4 from '../assets/toolsImages/com-img4.png';
import comImg5 from '../assets/toolsImages/com-img5.png';
import comImg6 from '../assets/toolsImages/com-img6.png';
import comImg7 from '../assets/toolsImages/com-img7.png';
import comImg8 from '../assets/toolsImages/com-img8.png';
import comImg10 from '../assets/toolsImages/com-img10.png';
import UImg from '../assets/toolsImages/uvalue-img.jpg';

// Stakeholders Images
import lums from '../assets/lums.jpeg';
import cda from '../assets/cda.jpeg';
import pha from '../assets/pha.jpeg';
import pda from '../assets/pda.jpeg';
import neeca from '../assets/neeca.jpeg';
import giz from '../assets/giz.jpeg';

// SDGs Images
import sdg7 from '../assets/sdg7.jpeg';
import sdg11 from '../assets/sdg11.jpeg';
import sdg12 from '../assets/sdg12.jpeg';
import sdg13 from '../assets/sdg13.jpeg';

const tools = [
  {
    name: 'Life Cycle Assessment (LCA)',
    description:
      'This tool calculates the carbon emissions along with the estimated cost of the construction project based on the specific details of the construction project entered by the user.',
    link: 'https://lca.berc.pk/',
    images: [lcaImg1, lcaImg2, lcaImg3, lcaImg4, lcaImg5, lcaImg6, lcaImg7],
  },
  {
    name: 'Residential Load Factor (RLF)',
    description: 'An evaluation tool for assessing residential energy consumption patterns.',
    link: 'https://rlf.berc.pk/login.php',
    images: [rlfImg1, rlfImg2, rlfImg3],
  },
  {
    name: 'Building Performance Design (BPD)',
    description: 'A tool for designing energy-efficient building performance strategies.',
    link: 'https://bpd.berc.pk/',
    images: [bpdImg1, bpdImg2, bpdImg3, bpdImg4],
  },
  {
    name: 'ECBC Compliance Tool',
    description: 'A tool for ensuring compliance with the Energy Conservation Building Code (ECBC).',
    link: 'https://compliance.berc.pk',
    images: [comImg3, comImg4, comImg5, comImg6, comImg7, comImg8, comImg10],
  },
  {
    name: 'U-Values Finder',
    description: 'A tool for finding the U-values of various building materials.',
    link: 'https://berc.pk/uvalues/',
    images: [UImg],
  },
  {
    name: 'U-Value Calculator',
    description: 'A tool for finding the U-values of various building materials.',
    link: 'https://berc.pk/uvalues/',
    images: [UImg],
  },
];

const aboutUsItems = [
  {
    name: 'Mission Statement',
    description:
      'BERC accelerates a sustainable built environment in Pakistan through innovative R&D, empowering building stakeholders with expertise, and advocating for energy-efficient policies',
  },
  {
    name: 'Our Expertise',
    description: 'Dummy description for Our Expertise.',
  },
  {
    name: 'Our Stakeholders',
    description: '',
    images: [giz, neeca, cda, pda, pha, lums],
  },
  {
    name: 'How We Work',
    description: 'Dummy description for How We Work.',
  },
  {
    name: 'Guiding Principle',
    description: 'Dummy description for Guiding Principle.',
  },
  {
    name: 'Supported SDGs',
    description: 'Description for Supported SDGs.',
    images: [sdg7, sdg11, sdg12, sdg13],
  },
];

const ourImpactItems = [
  {
    name: 'Impact Stories',
    description: 'Dummy description for Impact Stories.',
  },
  {
    name: 'Impact Summary',
    description: 'Dummy description for Impact Summary.',
  },
  {
    name: 'Feedback',
    description: 'Watch our users share their experiences and feedback.',
    videos: [
      'https://www.youtube.com/embed/dH7NBBnCUJw?si=f28lfghv3v8MrAha',
      'https://www.youtube.com/embed/LtYXMcWzkuY?si=Cr0JjXuZlNR5iSnv',
      'https://www.youtube.com/embed/pcvTdpB-ItE?si=Mw_TMFL60kyi_vng',
    ],
  },
];


const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isToolsHovered, setIsToolsHovered] = useState(false);
  const [activeTool, setActiveTool] = useState(null);
  const [isAboutUsHovered, setIsAboutUsHovered] = useState(false);
  const [activeAboutUsItem, setActiveAboutUsItem] = useState(null);
  const [isOurImpactHovered, setIsOurImpactHovered] = useState(false);
  const [activeOurImpactItem, setActiveOurImpactItem] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMouseEnterTools = () => {
    setIsToolsHovered(true);
    setIsAboutUsHovered(false);
    setIsOurImpactHovered(false);
  };

  const handleMouseLeaveTools = () => {
    setIsToolsHovered(false);
    setActiveTool(null);
  };

  const handleToolClick = () => {
    setIsToolsHovered(false);
    setActiveTool(null);
  };

  const handleMouseEnterAboutUs = () => {
    setIsAboutUsHovered(true);
    setIsToolsHovered(false);
    setIsOurImpactHovered(false);
  };

  const handleMouseLeaveAboutUs = () => {
    setIsAboutUsHovered(false);
    setActiveAboutUsItem(null);
  };

  const handleAboutUsItemClick = () => {
    setIsAboutUsHovered(false);
    setActiveAboutUsItem(null);
  };

  const handleMouseEnterOurImpact = () => {
    setIsOurImpactHovered(true);
    setIsToolsHovered(false);
    setIsAboutUsHovered(false);
  };

  const handleMouseLeaveOurImpact = () => {
    setIsOurImpactHovered(false);
    setActiveOurImpactItem(null);
  };

  const handleOurImpactItemClick = () => {
    setIsOurImpactHovered(false);
    setActiveOurImpactItem(null);
  };

  return (
    <div>
      <nav className="fixed top-0 left-0 w-full shadow-md z-20 bg-[#fafafa] transition-all duration-300">
        <div
          className="flex items-center justify-between px-4 lg:px-12 transition-all duration-300"
          style={{ width: '100%' }}
        >
          {/* Left Side Links */}
          <div className="flex items-center space-x-6 flex-grow justify-start">
            <ul className="hidden md:flex space-x-6">
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a href="#home" className="text-lg font-medium text-gray-700 hover:text-blue-500">
                  Home
                </a>
              </li>
              <li
                className="relative transform hover:scale-110 transition-transform duration-300"
                onMouseEnter={handleMouseEnterAboutUs}
              >
                <button className="flex items-center text-lg font-medium text-gray-700 hover:text-blue-500 focus:outline-none">
                  About Us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 ml-1"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a href="#services" className="text-lg font-medium text-gray-700 hover:text-blue-500">
                  Our Services
                </a>
              </li>
              <li
                className="relative transform hover:scale-110 transition-transform duration-300"
                onMouseEnter={handleMouseEnterTools}
              >
                <button className="flex items-center text-lg font-medium text-gray-700 hover:text-blue-500 focus:outline-none">
                  Tools
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 ml-1"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>

          {/* Centered Logo */}
          <div className="flex-shrink-0">
            <img src={logo} alt="Logo" className="h-16 mx-auto" />
          </div>

          {/* Right Side Links */}
          <div className="flex items-center space-x-6 flex-grow justify-end">
            <ul className="hidden md:flex space-x-6">
              <li
                className="transform hover:scale-110 transition-transform duration-300"
                onMouseEnter={handleMouseEnterOurImpact}
              >
                <button className="flex items-center text-lg font-medium text-gray-700 hover:text-blue-500 focus:outline-none">
                  Our Impact
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-5 h-5 ml-1"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a href="#news-events" className="text-lg font-medium text-gray-700 hover:text-blue-500">
                  News & Events
                </a>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a href="#team-members" className="text-lg font-medium text-gray-700 hover:text-blue-500">
                  Team
                </a>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a href="#gallery" className="text-lg font-medium text-gray-700 hover:text-blue-500">
                  Gallery
                </a>
              </li>
              <li className="transform hover:scale-110 transition-transform duration-300">
                <a
                  href="#partner"
                  className="text-lg font-medium hover:text-white bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
                  onClick={handleOpen}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Expanded Navbar showing About Us and descriptions */}
        {isAboutUsHovered && (
          <div
            className={`absolute top-full left-1/2 transform -translate-x-1/2 flex w-3/5 mx-auto bg-white shadow-lg z-10 
    transition-all duration-500 ease-in-out opacity-0 scale-95 ${isAboutUsHovered ? 'opacity-100 scale-100' : ''}`}
            onMouseEnter={handleMouseEnterAboutUs}
            onMouseLeave={handleMouseLeaveAboutUs}
          >
            {/* First div with titles */}
            <div className="w-1/2 p-4">
              <ul className="space-y-4">
                {aboutUsItems.map((item, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveAboutUsItem(item)}
                    onClick={handleAboutUsItemClick}
                    className={`text-lg font-bold text-center cursor-pointer transition-colors duration-300 ${activeAboutUsItem === item ? 'text-blue-500' : 'text-gray-700 hover:text-blue-500'
                      }`}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>

            {/* Vertical Line */}
            <div className="w-px bg-gray-300"></div>

            {/* Second div with description */}
            <div className="w-1/2 p-6 flex flex-col items-center justify-start">
              {activeAboutUsItem ? (
                <div className="text-center w-full">
                  <h2 className="text-2xl font-bold">{activeAboutUsItem.name}</h2>
                  <p className="mt-2 text-lg mb-4">{activeAboutUsItem.description}</p>
                  {activeAboutUsItem.images && (
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                      {activeAboutUsItem.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          className="w-32 h-auto mx-auto"
                          alt={`${index + 1}`}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-lg text-gray-500 text-center">
                  <p className="font-bold text-2xl mb-4">Learn More About Us</p>
                  <p className="mb-2">Hover over an item to see its description.</p>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Expanded Navbar showing Tools and descriptions */}
        {isToolsHovered && (
          <div
            className={`absolute top-full left-1/2 transform -translate-x-1/2 flex w-4/5 mx-auto bg-white shadow-lg z-10 
    transition-all duration-500 ease-in-out opacity-0 scale-95 ${isToolsHovered ? 'opacity-100 scale-100' : ''}`}
            onMouseEnter={handleMouseEnterTools}
            onMouseLeave={handleMouseLeaveTools}
          >
            {/* First div with tool names */}
            <div className="w-1/2 p-4">
              <ul className="space-y-4">
                {tools.map((tool, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveTool(tool)}
                    onClick={handleToolClick}
                    className={`text-lg font-bold text-center cursor-pointer transition-colors duration-300 ${activeTool === tool ? 'text-blue-500' : 'text-gray-700 hover:text-blue-500'
                      }`}
                  >
                    {tool.name}
                  </li>
                ))}
              </ul>
            </div>

            {/* Vertical Line */}
            <div className="w-px bg-gray-300"></div>

            {/* Second div with tool descriptions */}
            <div className="w-1/2 p-6 flex flex-col items-center justify-start">
              {activeTool ? (
                <div className="text-center w-full">
                  <h2 className="text-2xl font-bold">{activeTool.name}</h2>
                  <p className="mt-2 text-lg mb-4">{activeTool.description}</p>
                  {/* Carousel for images */}
                  <Carousel showThumbs={false}>
                    {activeTool.images.map((image, i) => (
                      <div key={i}>
                        <img src={image} alt={`Slide ${i}`} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="text-lg text-gray-500 text-center">
                  <p className="font-bold text-2xl mb-4">Explore Our Cutting-Edge Tools</p>
                  <p className="mb-2">
                    Our platform offers a wide range of powerful tools designed to enhance the efficiency and sustainability of building projects. From calculating carbon emissions to evaluating energy consumption, each tool is tailored to specific needs.
                  </p>
                  <p className="mb-4">
                    Whether you're designing a building for performance or ensuring compliance with energy codes, hover over a tool to explore its unique features and benefits.
                  </p>
                  <p className="text-lg">
                    Hover over a tool to see its description and detailed images.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Expanded Navbar showing Our Impact and descriptions */}
        {isOurImpactHovered && (
          <div
            className={`absolute top-full left-1/2 transform -translate-x-1/2 flex w-4/5 mx-auto bg-white shadow-lg z-10 
    transition-all duration-500 ease-in-out opacity-0 scale-95 ${isOurImpactHovered ? 'opacity-100 scale-100' : ''}`}
            onMouseEnter={handleMouseEnterOurImpact}
            onMouseLeave={handleMouseLeaveOurImpact}
          >
            {/* First div with titles */}
            <div className="w-1/2 p-4">
              <ul className="space-y-4">
                {ourImpactItems.map((item, index) => (
                  <li
                    key={index}
                    onMouseEnter={() => setActiveOurImpactItem(item)}
                    onClick={handleOurImpactItemClick}
                    className={`text-lg font-bold text-center cursor-pointer transition-colors duration-300 ${activeOurImpactItem === item ? 'text-blue-500' : 'text-gray-700 hover:text-blue-500'
                      }`}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>

            {/* Vertical Line */}
            <div className="w-px bg-gray-300"></div>

            {/* Second div with description */}
            <div className="w-1/2 p-6 flex flex-col items-center justify-start">
              {activeOurImpactItem && activeOurImpactItem.name === 'Feedback' ? (
                <div className="text-center w-full">
                  <h2 className="text-2xl font-bold">{activeOurImpactItem.name}</h2>
                  <p className="mt-2 text-lg mb-4">{activeOurImpactItem.description}</p>
                  <Carousel showThumbs={false} dynamicHeight={false} showIndicators={true}>
                    {activeOurImpactItem.videos.map((videoUrl, index) => (
                      <div key={index} className="video-container">
                        <iframe
                          width="100%"
                          height="400"
                          src={videoUrl}
                          title={`Feedback Video ${index + 1}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          style={{ margin: '0 auto' }}
                        ></iframe>
                      </div>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="text-lg text-gray-500 text-center">
                  <p className="font-bold text-2xl mb-4">Discover Our Impact</p>
                  <p className="mb-2">Hover over an item to see its description.</p>
                </div>
              )}
            </div>
          </div>
        )}

        <Partner open={open} handleClose={handleClose} />
      </nav>
      <ScrollToTopButton />
    </div>
  );
};

export default Navbar;
