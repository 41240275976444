import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faComments, faFlask, faRecycle, faArrowRight, faBuilding, faComputer } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  return (
    <>
      <section id="services" className="min-h-screen flex flex-col items-center justify-center p-8 bg-white">
        <h1 className="text-4xl font-bold mb-12">BERC Services</h1>
        <p className="text-gray-700  max-w-4xl text-justify">
      By offering comprehensive solutions in research, consulting, policy, education, and advisory services, BERC is actively contributing to the creation of energy-efficient, green sustainable, and resilient buildings across the country.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 w-full max-w-6xl mt-12">
          {/* Certificate Div with Button */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faCertificate} className=" mb-4" style={{ fontSize: '100px', color: "#FFD700" }} />
            <h2 className="text-2xl font-bold text-gray-800">Advisory Services</h2>
            <p className="text-gray-600 mb-4">Expert guidance on acheiving certifications such as ISO 50001 for energy management and Edge</p>
            <div className="mt-auto w-full">
              <button
                className="w-full bg-blue-500 text-white font-bold py-2 rounded-lg text-sm transition-all duration-300 hover:bg-blue-600 hover:shadow-lg flex items-center justify-center group"
              >
                Contact Us
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 transition-all group-hover:ml-4" />
              </button>
            </div>
          </div>

          {/* Consultation Div with Button */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faComments} className=" mb-4" style={{ fontSize: '100px', color: "#FF6347" }} />
            <h2 className="text-2xl font-bold text-gray-800">Engineering Consultating Services</h2>
            <p className="text-gray-600 mb-4">Consultation About the New and Old Buildings</p>
            <div className="mt-auto w-full">
              <button
                className="w-full bg-blue-500 text-white font-bold py-2 rounded-lg text-sm transition-all duration-300 hover:bg-blue-600 hover:shadow-lg flex items-center justify-center group"
              >
                Request Free Consultation
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 transition-all group-hover:ml-4" />
              </button>
            </div>
          </div>
          {/* Energy Modeling Div with Button */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faBuilding} className=" mb-4" style={{ fontSize: '100px', color: "#4682B4" }} />
            <h2 className="text-2xl font-bold text-gray-800">Energy Modeling</h2>
            <p className="text-gray-600 mb-4">Building Energy Modeling, Simulation and Energy Auditing for Buildings</p>
            <div className="mt-auto w-full">
              <button
                className="w-full bg-blue-500 text-white font-bold py-2 rounded-lg text-sm transition-all duration-300 hover:bg-blue-600 hover:shadow-lg flex items-center justify-center group"
              >
                Request Demo
                <FontAwesomeIcon icon={faArrowRight} className="ml-2 transition-all group-hover:ml-4" />
              </button>
            </div>
          </div>
          {/* Research and Development Div with Button */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faFlask} className=" mb-4" style={{ fontSize: '100px', color: "#6A5ACD" }} />
            <h2 className="text-2xl font-bold text-gray-800">Research and Development</h2>
            <p className="text-gray-600 mb-4">Research and Development of New and Old Buildings</p>
            <div className="mt-auto w-full"></div>
          </div>

          {/* Transformation Div with Button */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faRecycle} className=" mb-4" style={{ fontSize: '100px', color: "#32CD32" }} />
            <h2 className="text-2xl font-bold text-gray-800">Policy and Advocacy</h2>
            <p className="text-gray-600 mb-4">Policy Research and sensitization workshops for policy makers</p>
            <div className="mt-auto w-full"></div>
          </div>

          {/* capacity dev */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faRecycle} className=" mb-4" style={{ fontSize: '100px', color: "#FFA500" }} />
            <h2 className="text-2xl font-bold text-gray-800">Capacity Development</h2>
            <p className="text-gray-600 mb-4">Offers a comprehensive range of Training Programs and Webinars on Energy Efficient Buildings </p>
            <div className="mt-auto w-full"></div>
          </div>


          {/* Software Development Div with Button */}
          <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105 relative flex flex-col">
            <FontAwesomeIcon icon={faComputer} className=" mb-4" style={{ fontSize: '100px', color: "#1E90FF" }} />
            <h2 className="text-2xl font-bold text-gray-800">Software Development</h2>
            <p className="text-gray-600 mb-4">Custom Software Development for Building Energy Management</p>
            <div className="mt-auto w-full">

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;



